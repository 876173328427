import React, { useEffect, useState } from "react";
import { Col, Row, Form, Container, Button, FormGroup, Modal } from "react-bootstrap";
import { SearchLedger, SearchLedgerByDate, getinventoryData } from "./materialledger.actions";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import "./style.css"
import { Link } from 'react-router-dom';
import Select from 'react-select';

const MaterialLedger = (props) => {

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [item, setItem] = useState('');
  const [itemCode, setItemCode] = useState('');

  const getledger = props.ledgerSearchlist;
  const getItem = props.inventoryList.map((x) => { let data = { value: x.inventoryId, label: x.warehouse.whKey + "-" + x.category.cateKey + "-" + x.invKey + " " + x.productName }; return data });
  const handleChangeLedgerItem = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const [state, setState] = React.useState({
    columns: [
      { title: 'Date', field: 'updatedAt', render: rowData => moment(rowData.updatedAt).format("YYYY-MM-DD") },
      { title: 'Item Name', field: 'metrialName' },
      { title: 'Item Code', field: 'metrialCode' },
      { title: 'D-P-I', field: '' },
      { title: 'Previous Balance', field: 'balance' },
      { title: 'Add Item', field: 'add' },
      { title: 'Less Item', field: 'less' },
      { title: 'New Balance', field: 'newBalance' },

    ],
  });

  useEffect(() => {
    props.getinventoryData();
    //  props.SearchLedger();
    // props.SearchLedgerByDate({
    //   startedDate: "",
    //   endDate: "",
    //   item: "",
    //   itemCode: ""
    // });

  }, []);
  function searchData() {
    props.SearchLedgerByDate({
      startedDate: startDate,
      endDate: endDate,
      item: item,
      itemCode: itemCode
    });
  }
  return (
    <>
      {props.isFetchinginventoryData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading

          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">

            <div>
              <Form>


                <Row>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Start Date </b>

                      </Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> End Date</b>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}

                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Select Item </b>
                      </Form.Label>
                      <Select
                        placeholder="Select item..."
                        onChange={handleChangeLedgerItem}
                        options={getItem}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg="3" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Item Code</b>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setItemCode(e.target.value)}
                        type="text"
                        readOnly
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row>

                  <Col lg="12" sm="12" className="text-center" style={{marginTop: '30px'}}>
                    <Link to="/inventory/material-ledger-details">
                      <Button className="sendButton" onClick={(e) => searchData(e.target.value)} >Search</Button>
                    </Link>
                  </Col>
                </Row>
              </Form>

            </div>

          </div>

        </Container>

      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getinventoryData: () => dispatch(getinventoryData()),
    SearchLedger: () => dispatch(SearchLedger()),
    SearchLedgerByDate: (data) => dispatch(SearchLedgerByDate(data)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  ledgerlist: state.MaterialLegderReducer.ledgerlist,
  ledgerSearchlist: state.MaterialLegderReducer.ledgerSearchlist,
  inventoryList: state.MaterialLegderReducer.inventoryData,
  isFetchinginventoryData: state.MaterialLegderReducer.isFetchinginventoryData
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialLedger);