import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL ,headers} from "../../../../../global/api";
import { deleteProduct, getProductData, updateProduct } from "./Product.action";
import { setHeading } from "../../../accounts/account.actions";
import { isEmpty } from "lodash";
const ViewProduct = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  useEffect(() => {
    props.setHeading("Product List");
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
        <MaterialTable
                  title="Product Details"
                  columns={[
                    { title: 'Product Code', field: 'code', editable: 'never' },
                    { title: "Product Name", field: "name" ,
                    render: rowData => 
                    <div style={{
                      backgroundColor: rowData.productTypeName=="Service Product"?"transparent": Number(rowData.productBalance) <= Number(rowData.minimumBalance) ? '#ff8d8d' : Number(rowData.productBalance) >= Number(rowData.maximumBalance) ? '#addfac' : 'transparent'
                  }}> {rowData.name}</div> 
                },
                    // { title: "Product Discount", field: "discount" },
                    { title: "Product Description", field: "desc" },
                    { title: "opening Balance", field: "openingBalance", editable:'never'},
                    { title: "Product Balance", field: "productBalance",editable:'never' },
                    // { title: "product Type Name", field: "productTypeName",editable:'never' },
                    { title: "Product Purchase Price", field: "purchasePrice" },
                    { title: "Product Sale Price", field: "salePrice" },
                    // { title: "Product Red Color Time", field: "redColorTime" },
                    // { title: "Product Yellow Color Time", field: "yellowColorTime" },
                    // { title: "Product Green Color Time", field: "greenColorTime" },//uomPrice
                    { title: "Warehouse", field: "wareHouseId.wareHouseName",editable:'never' },
                    { title: "Category", field: "category.name",editable:'never' },
                    { title: "Sub Category", field: "subCategoryId.subCategoryName",editable:'never' },
                  ]}
                 // data={wareHouseData}
                     data={(query) =>
                      new Promise((resolve, reject) => {
                          let url = `${baseURL}/products?`;
                          url += "limit=" + query.pageSize;
                          url += "&page=" + (query.page);
                           url += "&search=" + query.search;
                          fetch(url, {
                              method: 'GET',
                              headers: headers,
                          })
                              .then((response) => response.json())
                              .then((result) => {
                                  resolve({
                                      data: result.data,
                                      page: result.page,
                                      totalCount: result.total_results,
                                  });
                              });

                      })
                  }
                
                //   detailPanel={rowData => {
                //     return (
                //         <MaterialTable
                //             //columns={state.columnsData}
                //             columns={[
                //                 { title: 'Flavor Name ', field: 'flavorLabel' },
                //                 { title: 'Size', field: 'sizeLabel' },
                //                 { title: 'Selling Price', field: 'sellingPrice' },
                //             ]}
                //             data={rowData.variation}
                //             // data={(query) =>
                //             //     new Promise((resolve, reject) => {
                //             //         let url = `${baseURL}/receiving?`;
                //             //         url += "limit=" + query.pageSize;
                //             //         url += "&page=" + (query.page);
                //             //         url += "&search=" + query.search;
                //             //         fetch(url, {
                //             //             method: 'GET',
                //             //             headers: headers,
                //             //         })
                //             //             .then((response) => response.json())
                                        
                //             //             .then((result) => {
                //             //                 let a=-1;
                //             //                 resolve({
                                                
                //             //                     data: result.data[a+1].receivingData,
                //             //                     page: result.page,
                //             //                     totalCount: result.total_results,
                //             //                 });
                //             //             });
                                        
                //             //     })
                //             // }                            
                //             options={{
                //                 actionsColumnIndex: -1,
                //                 toolbar: false,
                //                 exportButton: true,
                //                 sorting: true,
                //                 paging: true,
                //                 pageSize: 200,       // make initial page size
                //                 emptyRowsWhenPaging: false,   // To avoid of having empty rows
                //                 pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                //                 headerStyle: {
                //                     position: 'sticky', top: 0,
                //                     color: '#00BBBB',
                //                     fontWeight: '550',
                //                     onRowAdd: 'none',
                //                 }
                //             }}

                //         />
                //     )
                // }}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 50, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200], // rows selection options
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Product Edit"),
                    isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Product Delete"),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        console.log(newData, oldData,"ttttttttttttttttttttt")
                        setTimeout(() => {
                          resolve();
                          {
                            props.updateProduct(newData, oldData);
                          }
                        }, 600);
                      }),
                      isDeletable:false,
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteProduct(oldData._id);
                        }, 600);
                      }),
                  }}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProductData: () => dispatch(getProductData()),
    deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    updateProduct: (newData, oldData) =>
      dispatch(updateProduct(newData, oldData)),
      setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  flavorsDataList: state.FlavourReducer.flavorsDataList,
  sizeDataList: state.sizeReducer.sizeDataList,
  masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);
