const initialState = {
    addUser:[],
    isAddingUser:false,
    UserList:[],
    isFetchingUser:false,
    isDeletingUser:false,
}
const UserReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {
case "REQUEST_ADD_USER_RES":
    return { ...state, isAddingUser: true };
case "SUCCESS_ADD_USER_RES":
    return {
        ...state,
        addUser: action.payload,
        isAddingUser: false,
    };
case "ERROR_ADD_USER_RES":
    return { ...state, isAddingUser: false };
    case "REQUEST_GET_USER_RES":
        return { ...state, isFetchingUser: true };
    case "SUCCESS_GET_USER_RES":
        return {
            ...state,
            userList: action.payload.data,
            isFetchingUser: false,
        };
    case "ERROR_GET_USER_RES":
        return { ...state };
        case "REQUEST_DELETE_USER_RES":
            return { ...state, isDeletingUser: false };
        case "SUCCESS_DELETE_USER_RES":
            return { ...state, isDeletingUser: true };
        case "ERROR_DELETE_USER_RES":
            return { ...state, isDeletingUser: false };
            default:
            return state;
    }
};
export default UserReducer;