import API from "../../../../global/api";

export const requestinventoryData = () => {
    return {
        type: "REQUEST_IVENTORY_DATA",
    };
};
export const successinventoryData = (data) => {
    return {
        type: "SUCCESS_IVENTORY_DATA",
        payload: data,
    };
};
export const errorinventoryData = () => {
    return {
        type: "ERROR_IVENTORY_DATA",
    };
};
export const getinventoryData = () => {
    return (dispatch) => {
        dispatch(requestinventoryData());
        API.get(`/inventory/all-inventory-data  `).then((res) => {
            let data1 = res.data;
            dispatch(successinventoryData(data1));
        }).catch((error) => {
            dispatch(errorinventoryData());
        })
    };
};

export const requestSearchLedger = () => {
    return {
        type: "REQUEST_ADD_LEDGER",
    };
};
export const successSearchLedger = (data) => {
    return {
        type: "SUCCESS_ADD_LEDGER",
        payload: data,
    };
};
export const errorSearchLedger = () => {
    return {
        type: "ERROR_ADD_LEDGER",
    };
};


export const SearchLedger = () => {
    return (dispatch) => {
        dispatch(requestSearchLedger());
        API.get(`/inventory/getInventoryHistory`).then((res) => {
            let data1 = res.data;
            dispatch(successSearchLedger(data1));
            
        }).catch((error) => {
            dispatch(errorSearchLedger());
        })
    };
};


export const requestSearchLedgerByDate = () => {
    return {
        type: "REQUEST_ADD_LEDGER_BY_DATE",
    };
};
export const successSearchLedgerByDate = (data) => {
    return {
        type: "SUCCESS_ADD_LEDGER_BY_DATE",
        payload: data,
    };
};
export const errorSearchLedgerByDate = () => {
    return {
        type: "ERROR_ADD_LEDGER_BY_DATE",
    };
};


export const SearchLedgerByDate = (data) => {
    return (dispatch) => {
        dispatch(requestSearchLedgerByDate());
        API.post(`/inventory/getinventoryhistoryByDate`, data).then((res) => {
            let data1 = res.data;
            dispatch(successSearchLedgerByDate(data1));
            
        }).catch((error) => {
            dispatch(errorSearchLedgerByDate());
        })
    };
};




