import React, { useEffect } from "react";
import { Row, Col, Form, Container, Button, Alert, Tabs, Tab } from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, AddLevel2Name, getLevel2Data } from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, groupBy, sortBy } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../../../Images/logo.png";
import moment from "moment";
import { Link } from "react-router-dom";


const Level2Tap = (props) => {

  const level1Data = sortBy(props.level2List, ['props.level2List', 'levelTwoData.allKey'])
  const [state, setState] = React.useState({
    columns: [
      { title: 'Level 1 Name', field: 'levelTwoData.level_one.name', render: rowData => rowData.levelTwoData.level_one.name + " /" + rowData.levelTwoData.level_one.key },
      { title: 'Level 2 Code', field: 'levelTwoData.allKey', editable: 'never' },
      { title: 'Level 2 Name', field: 'levelTwoData.levelTwoName' },
    ],
  });

  useEffect(() => {
    // props.getLevel1Data();
    props.getLevel2Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel2List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />

          </Button>
        </div>
      ) : (
        <Container fluid>

          <div className="sendDiv">
            <Link to="/user/level2"> <Button className="sendButton">Back</Button></Link>
          </div>
          <MaterialTable
            title="Level 2 Details"
            //  columns={state.columns}
            columns={[
              { title: 'Level 1 Name', field: 'levelTwoData.level_one.name', render: rowData => rowData.levelTwoData.level_one.name + " /" + rowData.levelTwoData.level_one.key },
              { title: 'Level 2 Code', field: 'levelTwoData.allKey', editable: 'never' },
              { title: 'Level 2 Name', field: 'levelTwoData.levelTwoName' },
            ]}
          data={level1Data}
          options={{
            actionsColumnIndex: -1,
            exportAllData: true,
            exportButton: true,
            pageSize: 30,
            sorting: true,
            pageSizeOptions: [30, 50, 100],
            emptyRowsWhenPaging: false,
            exportPdf: (columns, data) => {
              const doc = new jsPDF();

              const columnTitles = state.columns
                .map(columnDef => columnDef.title);

              // const pdfData = data.map(rowData =>
              //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
              // );

              const pdfData = data.map((elt) => [elt.levelTwoData.level_one.name, elt.levelTwoData.allKey, elt.levelTwoData.levelTwoName]);
              let content = {
                startY: 50,
                startX: 5,
                head: [columnTitles],
                body: pdfData
              };
              doc.addImage(logo, 'PNG', 10, 11, 30, 30);
              doc.text("Company Name: Retro", 100, 20, 0, 20);
              doc.text("Level2 Report", 30, 45, 0, 20);
              doc.autoTable(content);

              doc.save(`data.pdf`);
            },
            headerStyle: {
              position: 'sticky', top: 0,
              color: '#00BBBB',
              fontWeight: '550',
              onRowAdd: 'none',
            }
          }}
          />

        </Container>

      )}
    </>
  );



}

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel2Data: () => dispatch(getLevel2Data()),
  };
};

const mapStateToProps = (state, ownProps) => ({
  level2List: state.accountsLevelReducer.level2List,
  isFetchinglevel2List: state.accountsLevelReducer.isFetchinglevel2List
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Level2Tap);