import React, { useEffect, useState } from "react";
import { Col, Row, Form, Container, Button, FormGroup, Modal } from "react-bootstrap";
import { SearchLedger, SearchLedgerByDate, getinventoryData } from "./materialledger.actions";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import "./style.css"
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { isEmpty, get } from "lodash";

const MaterialLedgerDetail = (props) => {

    const getledger = props.ledgerSearchlist.data;
    const name = get(!isEmpty(getledger) && getledger[1], "metrialName", "");
    const code = get(!isEmpty(getledger) && getledger[1], "metrialCode", "");

    const [state, setState] = React.useState({
        columns: [
            { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
            { title: 'Item Name', field: 'metrialName' },
            { title: 'Item Code', field: 'metrialCode' },
            { title: 'D-P-I', field: 'D_P_I' },
            // { title: 'Previous Balance', field: 'balance' },
            { title: 'Add Item', field: 'add' },
            { title: 'Less Item', field: 'less' },
            { title: 'New Balance', field: 'newBalance' },

        ],
    });

    return (
        <>
            {props.isFetchingSearchLedgerData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        Loading

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div className="sendDiv">
                            <Link to="/inventory/materialledger">
                                <Button className="sendButton"> Back to Search</Button>
                            </Link>
                        </div>
                        <h4>Total Add Item : {props.ledgerSearchlist.add}</h4>
                        <h4>Total Less Item : {props.ledgerSearchlist.less}</h4>
                        <h4>Closing Balance : {props.ledgerSearchlist.closing_balance}</h4>
                        <MaterialTable
                            title={name == "" ? "Ledgers" : `${name} (${code})`}
                            //columns={state.columns}
                            columns={[
                                { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                                // { title: 'Item Name', field: 'metrialName' },
                                // { title: 'Item Code', field: 'metrialCode' },
                                { title: 'D-P-I-R', field: 'D_P_I' },
                                { title: 'Description', field: 'inventory_ledger_type' },
                                // { title: 'Previous Balance', field: 'balance' },
                                { title: 'Add Item', field: 'add' },
                                { title: 'Less Item', field: 'less' },
                                { title: 'New Balance', field: 'newBalance' },

                            ]}
                            data={getledger}
                            options={{
                                paging: true,
                                pageSize: 30,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                        />

                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getinventoryData: () => dispatch(getinventoryData()),
        SearchLedger: () => dispatch(SearchLedger()),
        SearchLedgerByDate: (data) => dispatch(SearchLedgerByDate(data)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    ledgerlist: state.LegderReducer.ledgerlist,
    ledgerSearchlist: state.LegderReducer.ledgerSearchlist,
    inventoryList: state.LegderReducer.inventoryData,
    isFetchingSearchLedgerData: state.LegderReducer.isFetchingSearchLedgerData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialLedgerDetail);