import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert, Tabs, Tab } from "react-bootstrap";
import "./add-levels.css";
import { getLevel1Data, getLevel2Data, uploadLevel3File, getLevelOneName } from "./accountsLevel.action";
import { connect } from "react-redux";
import { find, isEmpty, isUndefined} from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';

const Level3Upload = (props) => {
    const [level1Id, setLevel1Id] = useState();
    const [level2Id, setLevel2Id] = useState();
    const [level3File, setLevel3File] = useState();
    const [level1Label, setLevel1Label] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
          });
    }
  
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
          });
    }

    function uploadFileLevel3() {
        props.uploadLevel3File({
            levelOneId: level1Id,
            levelTwoId: level2Id,
            file: level3File
        }, handleVisible, handleDangerVisible);
        setLevel1Label("");
    }

    const getLevel1List = props.level1List.map((x) => { let data = { value: x.levelOneId, label: x.name }; return data });
    const handleChangeLevel1 = (selectedOption) => {
        props.getLevelOneName(selectedOption.value);
      setLevel1Id(selectedOption.value);
      setLevel1Label(selectedOption.label);
    };

      const getLevelTwoList = !isEmpty(props.levelTow) && props.levelTow.map((x) => { let data = { value: x.levelTwoData.levelTwoId,  label: x.levelTwoData.levelTwoName}; return data });

      const handleChangeLevel2 = (selectedOption) => {
        setLevel2Id(selectedOption.value); 
        // setLevel1Id(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
    };
    useEffect(() => {
        props.getLevel1Data();
    }, []);
    return (
        <>
            {props.isFetchinglevel1List || props.isFetchingLevelTow ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                    <Row>
                                    <Col>
                                    <Form.Group>
                                        <Form.Label>
                                        <b> Select Level 1 </b>
                                        </Form.Label>
                                        <Select
                                        value = {{label: level1Label}}
                                        placeholder="Select Level 1..."
                                        onChange={handleChangeLevel1}
                                        options={getLevel1List}
                                        />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 2 </b>
                                                </Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1Label)}
                                                    placeholder="Select Level 2..."
                                                     onChange={handleChangeLevel2}
                                                      options={getLevelTwoList}
                                                      readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                        <div style={{ paddingTop: "2%" }}>
                            <Row>
                                <Col xl="4" lg="4" md="2" sm="2" xs="2"></Col>
                                <Col xl="4" lg="4" md="4" sm="4" xs="4">
                                    <Form.Group>


                                        <Form.Label>
                                            <b style={{ fontSize: "x-large" }}>Upload Level3 File</b>
                                        </Form.Label>


                                        <input className="form-control" type="file" name="file" class="Upload File" id="upload file" onChange={(e) => setLevel3File(e.target.files[0])} />


                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="sendDiv">
                        { !level2Id ?  <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Save</Button> : <Button className="sendButton" onClick={uploadFileLevel3}>Save</Button>}

                        </div>
                    </div>
                </Container>
            )}

        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel1Data: () => dispatch(getLevel1Data()),
        getLevel2Data: () => dispatch(getLevel2Data()),
        uploadLevel3File: (data, handleVisible, handleDangerVisible) => dispatch(uploadLevel3File(data, handleVisible, handleDangerVisible)),
        getLevelOneName: (data)=>dispatch(getLevelOneName(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    level1List: state.accountsLevelReducer.level1List,
    level2List: state.accountsLevelReducer.level2List,
    isAddingLevel3File: state.accountsLevelReducer.isAddingLevel3File,
    isnotAddingLevel3File: state.accountsLevelReducer.isnotAddingLevel3File,
    isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
    levelTow:state.accountsLevelReducer.levelTow,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level3Upload);