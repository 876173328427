const initialState = {
  usersList: [],
  permissionsList: [],
  addPermissions: [],

  isFetchingUsersList: false,
  isFetchingPermissionsList: false,
  isAddingPermissions: false
};

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_GET_Users":
      return { ...state, isFetchingUsersList: true };
    case "SUCCESS_GET_Users":
      return { ...state, usersList: action.payload, isFetchingUsersList: false }
    case "ERROR_GET_Users":
      return { ...state, isFetchingUsersList: false };

      case "REQUEST_GET_Permissions":
        return { ...state, isFetchingPermissionsList: true };
      case "SUCCESS_GET_Permissions":
        return { ...state, permissionsList: action.payload, isFetchingPermissionsList: false }
      case "ERROR_GET_Permissions":
        return { ...state, isFetchingPermissionsList: false };

        case "REQUEST_ADD_Permissions":
        return { ...state, isAddingPermissions: true };
      case "SUCCESS_ADD_Permissions":
        return { ...state, addPermissions: action.payload, isAddingPermissions: false }
      case "ERROR_ADD_Permissions":
        return { ...state, isAddingPermissions: false };

    default:
      return state;
  }

}
export default permissionReducer;

