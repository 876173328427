const initialState = {

    salaryData: [],
    isFetchingSalaryData: false,

}

const GenerateSalaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_POST_SALARY_DATA":
            return {
                ...state,
                isFetchingSalaryData: true
            };
        case "SUCCESS_POST_SALARY_DATA":
            return {
                ...state,
                salaryData: action.payload,
                isFetchingSalaryData: false,
            };
        case "ERROR_POST_SALARY_DATA":
            return { ...state };

        default:
            return state;
    }
};

export default GenerateSalaryReducer;